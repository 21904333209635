import tippy from 'tippy.js';
import { timeout } from '../../scripts/lib/timeout.js';

export function tooltip(selector = '[data-tooltip-content]', tippyParams = {}) {
  document.querySelectorAll(selector).forEach((element) => {
    tippyParams.arrow = element.dataset.tooltipArrow !== 'false';

    if ('tooltipPlacement' in element.dataset) {
      tippyParams.placement = element.dataset.tooltipPlacement;
    }
    if ('tooltipTheme' in element.dataset) {
      tippyParams.theme = element.dataset.tooltipTheme;
    }
    if ('tooltipMaxWidth' in element.dataset) {
      tippyParams.maxWidth = parseInt(element.dataset.tooltipMaxWidth, 10);
    }
    if ('tooltipAppendTo' in element.dataset) {
      const appendTo = document.querySelector(element.dataset.tooltipAppendTo);
      if (appendTo) {
        tippyParams.appendTo = appendTo;
      }
    }
    let offset = [0, 0];
    if ('tooltipHoffset' in element.dataset) {
      offset[0] = parseInt(element.dataset.tooltipHoffset, 10);
    }
    if ('tooltipVoffset' in element.dataset) {
      offset[1] = parseInt(element.dataset.tooltipVoffset, 10);
    }

    if ('tooltipSingletime' in element.dataset && element.dataset.tooltipSingletime !== 'false') {
      tippyParams.onHidden = (tippy) => {
        tippy.destroy();
      };
    }

    const t = tippy(element, {
      content: element.dataset.tooltipContent,
      allowHTML: true,
      duration: [600, 250],
      zIndex: 1000,
      offset,
      ...tippyParams,
    });

    if ('tooltipIsShown' in element.dataset && element.dataset.tooltipIsShown !== 'false') {
      const isShownDelay = parseInt(element.dataset.tooltipIsShownDelay ?? 0, 10);
      timeout(isShownDelay).then(() => t.show());
    }
  });
}
