export function onImageLoad(imageElement, timeout = 3000) {
  return new Promise((resolve, reject) => {
    if (timeout) setTimeout(reject, timeout);

    if (imageElement.complete) {
      resolve();
    } else {
      imageElement.addEventListener('load', resolve);
      imageElement.addEventListener('error', reject);
    }
  });
}

export function onAllImagesLoad(imagesElements, timeout = 3000) {
  return new Promise((resolve, reject) => {
    if (timeout) setTimeout(reject, timeout);

    const promises = imagesElements.map((element) => onImageLoad(element));
    Promise.allSettled(promises).then(resolve).catch(reject);
  });
}
