import Swiper, { FreeMode } from 'swiper';
import { gsap } from 'gsap';
import ky from 'ky';
import { onImageLoad } from '../../scripts/lib/onImageLoad.js';
import { getExceptIndex } from '../../scripts/lib/array.js';
import { tooltip } from '../tooltip/tooltip.js';
import ReadSmore from 'read-smore';

export function fullscreenModalLoader(Alpine) {
  Alpine.data('fullscreenModalLoader', ({ initialTab = 0 } = {}) => ({
    isOpen: false,
    url: null,
    controls: null,
    init() {
      this.initOpenTriggers();
    },
    async open(url, tab = null) {
      if (this.url && url === this.url) {
        if (tab) this.controls.showTab(tab, true);
        this.isOpen = true;
        return;
      }

      if (this.url) {
        if (this.controls) {
          this.controls.destroy();
          this.controls = null;
        }
      }

      this.url = url;
      this.$root.innerHTML = await ky.get(this.url).text();

      this.isOpen = true;
      this.controls = fullscreenModalControls(this.$root, initialTab);
      if (tab) this.controls.showTab(tab, true);

      const closeBtn = this.$root.querySelector('.fullscreen-modal__close');
      if (closeBtn) closeBtn.setAttribute('x-on:click.prevent', 'close()');
      if (closeBtn) closeBtn.setAttribute('x-on:keyup.space', 'close()');
      if (closeBtn) closeBtn.setAttribute('x-on:keyup.enter', 'close()');
    },
    close() {
      if (this.controls) this.controls.reset();
      this.isOpen = false;
    },
    initOpenTriggers() {
      document.querySelectorAll('[data-fullscreen-modal-url]').forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          if ('fullscreenModalTab' in item.dataset) {
            this.open(item.dataset.fullscreenModalUrl, item.dataset.fullscreenModalTab);
          } else {
            this.open(item.dataset.fullscreenModalUrl);
          }
        });
      });
    },
  }));
}

export function fullscreenModalControls(element, initialTab = null) {
  if (!element) return;

  const modal = element.classList.contains('fullscreen-modal')
    ? element
    : element.querySelector('.fullscreen-modal');
  const loading = element.querySelector('.fullscreen-modal__loading');
  const graphicsElement = element.querySelector('.fullscreen-modal__graphic');
  const expandImageButton = element.querySelector('.fullscreen-modal__expand-image');
  const shrinkImageButton = element.querySelector('.fullscreen-modal__shrink-image');
  const swiperElement = element.querySelector('.fullscreen-modal__swiper');
  const tabButtons = [...element.querySelectorAll('.fullscreen-modal__nav-button')];
  const tabPictures = [...element.querySelectorAll('.fullscreen-modal__picture')];
  const tabImages = [...element.querySelectorAll('.fullscreen-modal__picture img')];
  const tabContents = [...element.querySelectorAll('.fullscreen-modal__content-inner')];

  if (!initialTab) initialTab = modal.dataset?.initialTab;

  tooltip('.fullscreen-modal [data-tooltip-content]');
  onImageLoad(tabImages[0]).finally(() => gsap.to(loading, { autoAlpha: 0 }));

  let currentButton = null;
  let currentPicture = null;
  let currentContent = null;
  if (tabButtons.length > 0) {
    currentButton = tabButtons[0];
    currentPicture = tabPictures[0];
    currentContent = tabContents[0];
    currentButton.classList.add('is-active');
    gsap.set(getExceptIndex(tabPictures, 0), { autoAlpha: 0 });
    gsap.set(getExceptIndex(tabContents, 0), { display: 'none', autoAlpha: 0 });
  }

  const showTab = (tab, skipAnimation = false) => {
    const selectedButton = tabButtons.find((button) => button.dataset.tab === tab);
    const selectedPicture = tabPictures.find((button) => button.dataset.tab === tab);
    const selectedContents = tabContents.find((button) => button.dataset.tab === tab);

    if (currentButton === selectedButton) return;

    currentButton.classList.remove('is-active');
    selectedButton.classList.add('is-active');

    if (!skipAnimation) {
      gsap
        .timeline({})
        .to(currentContent, { autoAlpha: 0 })
        .to(currentPicture, { autoAlpha: 0 }, '<')
        .set(currentContent, { display: 'none' })
        .set(selectedContents, { display: 'block' })
        .to(selectedContents, { autoAlpha: 1 })
        .to(selectedPicture, { autoAlpha: 1 }, '<');
    } else {
      gsap
        .timeline({})
        .set(currentContent, { autoAlpha: 0 })
        .set(currentPicture, { autoAlpha: 0 })
        .set(currentContent, { display: 'none' })
        .set(selectedContents, { display: 'block' })
        .set(selectedContents, { autoAlpha: 1 })
        .set(selectedPicture, { autoAlpha: 1 });
    }

    currentButton = selectedButton;
    currentPicture = selectedPicture;
    currentContent = selectedContents;
  };

  if (initialTab) showTab(initialTab, true);

  const showNextTab = () => {
    let idx = tabButtons.indexOf(currentButton);
    if (idx === -1) return;
    idx++;
    if (idx >= tabButtons.length) idx = 0;
    showTab(tabButtons[idx].dataset.tab);
  };

  const showPrevTab = () => {
    let idx = tabButtons.indexOf(currentButton);
    if (idx === -1) return;
    idx--;
    if (idx < 0) idx = tabButtons.length - 1;
    showTab(tabButtons[idx].dataset.tab);
  };

  tabButtons.forEach((button) =>
    button.addEventListener('click', () => showTab(button.dataset.tab))
  );

  const handleKeyPresses = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        showPrevTab();
        break;
      case 'ArrowRight':
        showNextTab();
        break;
    }
  };

  shrinkImageButton.hidden = true;
  const expandImage = () => {
    graphicsElement.classList.add('is-expanded');
    shrinkImageButton.hidden = false;
  };
  const shrinkImage = () => {
    graphicsElement.classList.remove('is-expanded');
    shrinkImageButton.hidden = true;
  };

  let swiper = null;
  window.addEventListener('keydown', handleKeyPresses);
  expandImageButton.addEventListener('click', expandImage);
  shrinkImageButton.addEventListener('click', shrinkImage);

  if (swiperElement && !swiper) {
    swiper = new Swiper(swiperElement, {
      modules: [FreeMode],
      direction: 'horizontal',
      freeMode: true,
      forceToAxis: true,
      slidesPerView: 'auto',
    });
  }

  const readMores = element.querySelectorAll('.js-read-smore');
  ReadSmore(readMores, { isInline: true, lessText: 'read less', moreText: 'read more' }).init();

  return {
    reset: () => {
      shrinkImage();
    },
    showTab,
    destroy: () => {
      window.removeEventListener('keydown', handleKeyPresses);
      expandImageButton.removeEventListener('click', expandImage);
      shrinkImageButton.removeEventListener('click', shrinkImage);
      if (swiper) swiper.destroy();
    },
  };
}
